import {usePageStore} from "../store-pinia/page";
export default defineNuxtRouteMiddleware(async (to, from) => {
    const pageStore = usePageStore();

    if (process.client) {
        if (pageStore.redirectPath !== null) {
            const newPath = pageStore.redirectPath;
            pageStore.redirectPath = null;
            return navigateTo(newPath);
        }
        if (to.fullPath === pageStore.currentPagePath) return
        return navigateTo(to.fullPath, { external: true });
    }

    // Server-side logic
    const slugs = to.path.split('/').filter(Boolean);
    await pageStore.fetchPageData(slugs, slugs.length <= 0 ? 'landing-page' : null);

    pageStore.currentPagePath = to.fullPath;
});